import React, { useState } from "react";
import PopUpImage from "assets/images/quote_images/quote_popup_image.png";
import { useLocationGetInTouch } from "hooks/Forms/useLocationGetInTouch";
import { useForm, Controller } from "react-hook-form";
import ThankYouPopup from "components/popup/thankYouPopup";
import Loader from "components/loader";
import { useLocation } from "@reach/router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "gatsby";
import formCommonPostData from "utils/form-common-post-data";
import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget";
import RedExclamation from "assets/redexclon.svg";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";

export default function Modal({ open, setOpen }) {
  const [thankOpen, setThankOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const { status, mutateAsync } = useLocationGetInTouch();
  const location = useLocation();

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      phone: data.phonenumber,
      message: data.message,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };

    mutateAsync(postData).then((_) => {
      reset();
      setThankOpen(true);
      setOpen(false);
    });
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <>
      {open && (
        <React.Fragment>
          <div className="fixed overflow-x-hidden overflow-y-scroll  inset-0 bg-black w-full h-full z-50 opacity-70"></div>
          {/* <div className="justify-center items-center flex overflow-x-hidden overflow-y-scroll fixed inset-0 z-[200] outline-none bg-[#000000] opacity-70 focus:outline-none font-primary"></div> */}

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="relative  flex justify-between w-11/12 sm:w-auto bg-white flex-col lg:flex-row max-w-5xl  py-5 z-[200] lg:py-0 rounded-lg"
          >
            {/* <img
                src={PopUpImage}
                alt="loading..."
                className="w-18 hidden lg:block"
              /> */}
            <div className=" p-4 md:p-[40px] relative right-0 ">
              <button
                className="block ml-auto w-[24px]"
                type="button"
                onClick={handleClose}
              >
                <img src="https://tomedes.gumlet.io/frontend/images/home-en/popup_assets/close_dark.svg" />
              </button>
              <p className="text-center text-[30px] text-[#00173A] font-primary font-semibold leading-10">
                Get in touch
              </p>
              <p
                className="font-opensans text-base text-center px-4 lg:px-10 pb-6 leading-7 "
                style={{ color: "#000C27" }}
              >
                We guarantee a reply in a few minutes.
              </p>
              <div className="flex justify-between  flex-col ">
                <label className="flex flex-col w-full relative">
                  <span
                    className="modal-label font-opensans text-[11px] "
                    style={{ color: "#000C27" }}
                  >
                    Name<i className="text-red">*</i>
                  </span>
                  <input
                    type="text"
                    name="name"
                    className={`modal-input font-opensans text-[11px] text-pop-up-200 min-w-[230px] px-3 h-[45px] w-full border border-[#A1A1AA] rounded-md  ${
                      errors.name
                        ? "border-red outline-none focus:border-[#ff0000]"
                        : ""
                    }`}
                    placeholder="Your name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <img
                      src={RedExclamation}
                      alt="Error"
                      className="absolute right-2 top-[56%] transform -translate-y-1/2 w-4 h-4"
                    />
                  )}
                  <span
                    className={`text-red text-xs block ${
                      errors.name ? "visible" : "invisible"
                    }`}
                  >
                    {errorMessage}
                  </span>
                </label>

                <label className="flex flex-col w-full relative ">
                  <span
                    className="modal-label font-opensans text-[11px] "
                    style={{ color: "#000C27" }}
                  >
                    Email Address<i className="text-red">*</i>
                  </span>

                  <input
                    type="text"
                    name="email"
                    className={`modal-input font-opensans text-[11px] text-pop-up-200 min-w-[230px] px-2 h-[45px] w-full border border-[#A1A1AA]  ${
                      errors.email
                        ? "border-red outline-none focus:border-[#ff0000]"
                        : ""
                    }`}
                    placeholder="Your email address"
                    {...register("email", {
                      required: true,
                      pattern: patternEmail,
                    })}
                  />
                  {errors.email && (
                    <img
                      src={RedExclamation}
                      alt="Error"
                      className="absolute right-2 top-[56%] transform -translate-y-1/2 w-4 h-4"
                    />
                  )}
                  <span
                    className={`text-red text-xs block ${
                      errors.email ? "visible" : "invisible"
                    }`}
                  >
                    {errorMessageEmail}
                  </span>
                </label>
                <div className="flex flex-col w-full mb-4">
                  <label
                    htmlFor="phonenumber"
                    className="modal-label font-opensans text-[11px]"
                    style={{ color: "#000C27" }}
                  >
                    Contact Number
                  </label>
                  <Controller
                    name="phonenumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <PhoneInput
                        country={"us"}
                        value={field.value}
                        placeholder=""
                        onChange={(value) => field.onChange("+" + value)}
                        inputProps={{
                          name: "phonenumber",
                          id: "phonenumber",
                        }}
                        className="border rounded-md border-[#BBBBBB]  text-black h-[45px]"
                      />
                    )}
                  />
                </div>
              </div>
              <label>
                <span
                  className="modal-label font-opensans text-[11px] mt-1"
                  style={{ color: "#000C27" }}
                >
                  Message
                </span>
                <textarea
                  id=""
                  name="message"
                  rows="4"
                  style="resize:none;"
                  className="modal-input px-3 py-2 font-opensans text-[11px] text-pop-up-200 border  border-[#A1A1AA] focus:border-2  focus:border-pop-up-100  w-full "
                  placeholder="Tell us more about your needs"
                  {...register("message")}
                />
              </label>
              <div className="mb-9 relative">
                <div className="flex items-baseline gap-2 absolute top-[-4px]">
                  <p className="text-sm text-darkBlue font-opensans">
                    I agree to Tomedes'&nbsp;
                    <Link
                      to="/privacy"
                      className="text-orange hover:text-newOrange-100 underline transition-colors"
                    >
                      Privacy Policy
                    </Link>
                    &nbsp;by submitting this form.
                  </p>
                </div>
              </div>
              <input type="hidden" id="zc_gad" name="zc_gad" value="" />
              <button
                className="btn orn-btn w-full lg:w-32 h-10 text-white bg-orange font-opensans font-bold block mx-auto my-4"
                id="emailsubmit"
              >
                {status === "loading" ? <Loader /> : "SUBMIT"}
              </button>
              <TrustpilotReviews
                language="en"
                culture="US"
                theme="light"
                width="100%"
                height="20px"
                template="5419b637fa0340045cd0c936"
                business="5b18d84cd3737d0001df3bec"
                username="www.tomedes.com"
              />
            </div>
          </form>
          {/* </div> */}

          <div className="fixed inset-0 z-40 bg-black bg-opacity-50 lg:hidden"></div>
        </React.Fragment>
      )}
      <ThankYouPopup
        open={thankOpen}
        setOpen={setThankOpen}
        setOpenPopUp={setOpen}
      />
    </>
  );
}
