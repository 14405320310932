import React, { useState, useEffect } from "react";
import QuotePopUp from "components/popup/quotePopUp/index";
import moment from "moment";
import Layout from "components/layout";
import SEO from "components/seo";
import { Link } from "gatsby";

function InsiderDetail(props) {
  const [open, setOpen] = useState(false);
  const {
    pageContext: {
      data: { data },
    },
  } = props;

  return (
    <Layout>
      <SEO
        title={data.seo_project_title}
        description={data.seo_project_meta_desc}
        keywords={data.seo_project_meta_keywords}
        slug={data.slug}
      />
      <QuotePopUp open={open} setOpen={setOpen} />
      <div className="mainContainer text-white font-primary text-2xl tomedes-insider-main-wrapper">
        <div className="bannerImage bg-tomedes-insider bg-no-repeat bg-cover bg-center h-[460px] 2xl:h-[460px]">
          <div className="relative top-24 2xl:top-56 ml-0 pl-4 xl:ml-16 2xl:ml-416 laptop:ml-80">
            <div className="w-full max-w-max h-4 sm:h-4 md:h-8 mb-32">
              <h1 className="font-medium w-full font-riot ">{data.seo_h1}</h1>
            </div>
            <div className="mt-12">
              <div className="grid grid-cols-1 py-6 space-y-6 text-center md:flex md:flex-row md:align-baseline md:items-center md:space-x-6 md:space-y-0 font-opensans">
                <Link
                  to="/quote"
                  className="fr-btn1 uppercase rounded-full py-3 bg-orange text-white text-sm cursor-pointer w-60  font-semibold font-primary"
                >
                  Translate Now
                </Link>
                <a
                  onClick={() => setOpen(true)}
                  className="fr-btn2 uppercase rounded-full bg-white py-3 text-black text-sm  cursor-pointer w-60 font-semibold font-primary"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <QuotePopUp open={open} setOpen={setOpen} />
        <div className="flex items-center justify-center bg-darkestgray w-full tomedes-insider-wrapper">
          <div className="flex justify-evenly max-w-screen-2xl w-full xl:w-10/12 flex-col md:flex-row">
            <div className="md:w-7/12 w-full  px-6 sm:px-0">
              <h2 className=" mt-12 text-lightBlue font-opensans">{data.seo_h1}</h2>
              <h3 className=" mt-4 mb-3 text-blackNew-400 font-opensans">
                <i className="text-base">{moment(data.seo_project_create_date).format("MMMM DD, YYYY")}</i>
              </h3>
              <h3 className="mb-5 text-blackNew-400 font-opensans">
                <i className="text-lg">By Ofer Tirosh</i>
              </h3>
              <div
                className="font-normal text-base tracking-normal font-opensans text-white mb-8"
                dangerouslySetInnerHTML={{ __html: data.seo_project_content }}
              />
            </div>
            <div className="md:w-1/4 w-full md:mt-36 mt-11 sm:border-l sm:border-white sm:pl-6 h-2/3">
              <p className="text-lightBlue text-3xl mt-10  pb-8 font-primary text-center sm:text-left">Why choose us</p>
              <div className="sm:w-9/12 text-center mb-7">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/why-us-img/24-7-human-support.svg"
                  alt=""
                  className="m-auto inline-block w-20"
                />
                <p className="text-lg font-primary text-white mt-4">24/7 Human Support</p>
              </div>
              <div className="sm:w-9/12 text-center mb-7">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/why-us-img/quality-guaranteed-color.svg"
                  alt=""
                  className="m-auto inline-block w-20"
                />
                <p className="text-lg font-primary text-white mt-4">1 Year Guarantee</p>
              </div>
              <div className="sm:w-9/12 text-center mb-7">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/why-us-img/50000-business.svg"
                  alt=""
                  className="m-auto inline-block w-20"
                />
                <p className="text-lg font-primary text-white mt-4">95,000 Business Customers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default InsiderDetail;
